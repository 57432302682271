// Add alphabetically
export enum ActiveEndpoint {
  acnSearch = '/asic/search/by-acn',
  apiPing = '/api/ping',
  asyncPersonApplicationCreate = '/application/create',
  bnSearch = '/asic/company/search/by-name',
  checkout = '/v2_2/checkout',
  checkoutTokenization = '/msw/checkout/tokenization',
  contactZendeskCreate = '/contact/zendesk/create',
  dnSearch = '/dn/search',
  generateCustomPaymentLink = '/email/payment-link/generate',
  generatePaypalClientToken = '/msw/payment/generate/token',
  instantCowScoring = '/abr/get-score',
  logEssentials = '/log/essentials',
  logHome = '/log/home',
  logRegistration = '/log/registration',
  logRoute = '/log/route',
  logPayment = '/log/payment',
  paidApplication = '/segment/paid-application',
  personApplicationCreate = '/msw/person-application/create',
  registration = '/v2/service/create',
  retrieveUnpaidAppDetails = '/application/checkout/retrieve-details',
  sendCustomPaymentLink = '/email/payment-link/send',
  serverDowntime = '/check-server-downtime',
  v4Checkout = '/v4/checkout',
  v4RetrieveUnpaidAppDetails = '/v4/checkout/retrieve-details',
  applicationUpsert = '/application/upsert',
  v2PersonApplicationCreate = '/v2/application/create',
  v2retrieveUnpaidDetails = '/v2/application/unpaid-details',
  v3retrieveUnpaidDetails = '/v3/application/unpaid-details',
  v2updateDetails = '/v2/application/update',
  validateEmailAddress = '/validate/email-address',
  getEmailLink = '/gmb/send/email-link',
  getCheckoutLink = '/gmb/get/checkout-link',
  // getPaymentLinkDetails = '/gmb/get/link',
  retrieveApplicationDetails = '/gmb/get/application-details'
}
